<template>
  <div>
<!--    <span style="font-size: 10px">搜索：</span>-->
<!--    <el-select-->
<!--      style="width: 200px"-->
<!--      v-model="currentSubEquipnameId"-->
<!--      placeholder="请选择"-->
<!--      @change="selectCurrentpoint()"-->
<!--      v-loading="optionLoading"-->
<!--    >-->
<!--      <el-option-->
<!--        v-for="item in options"-->
<!--        :key="item.id"-->
<!--        :label="item.name"-->
<!--        :value="item.id"-->
<!--      >-->
<!--      </el-option>-->
<!--    </el-select>-->
<!--    tableData[scope.$index].before[index]里scope.$index为数组里第几个对象，index为这一行的第几个测点。-->
    <template>
      <el-table
        :data="tableData"
        style="width: 100%"
        @header-click="mpQuality"
        v-loading="dataListLoading"
      >
        <el-table-column
          prop="rowKey"
          label="日期"
          width="150"
        >
          <template slot-scope="scope">
            {{ tableData[scope.$index].rowKey }}
          </template>
        </el-table-column>
        <el-table-column
          prop="properties"
          :label="item.name"
          align="center"
          v-for="(item, index) in tableHeaderTest"
          :key="index"
        >
          <el-table-column
            label="是否存在问题"
            width="80"
          >
            <template slot-scope="scope">
              <div :style="
                  tableData[scope.$index].Isproblem[index] == '是'
                    ? 'color:#FF0000;'
                    : 'color:#000000;'
                ">
                {{ tableData[scope.$index].Isproblem[index] }}
              </div>

              <!-- {{tableData[scope.$index].properties[index].isProblem}} -->
            </template>
          </el-table-column>
          <el-table-column
            label="治理前"
            width="80"
          >
            <template slot-scope="scope">
              {{ tableData[scope.$index].before[index] }}
              <!-- {{tableData[scope.$index].properties[index].before}} -->
            </template>
          </el-table-column>
          <el-table-column
            label="治理后"
            width="80"
          >
            <template slot-scope="scope">
              {{ tableData[scope.$index].after[index] }}
              <!-- {{tableData[scope.$index].properties[index].after}} -->
            </template>
          </el-table-column>
        </el-table-column>

        <!-- <el-table-column prop="classify" label="所属分类" header-align="center" align="center" width="120">
                      </el-table-column> -->
        <!-- <el-table-column label="数据项值" header-align="center" align="center" width="120">
                          <template slot-scope="scope">
                              <el-button type="text" size="small" @click="pointnum(scope.row)">...</el-button>
                          </template>
                      </el-table-column> -->
        <el-table-column
          label="更多"
          header-align="center"
          align="center"
          width="120"
          v-if="moreVisible"
        >
<!--          <template slot-scope="scope">-->
<!--            <el-button-->
<!--              type="text"-->
<!--              size="small"-->
<!--              @click="more(scope.row)"-->
<!--            >...</el-button>-->
<!--          </template>-->
        </el-table-column>
      </el-table>
    </template>

    <!-- 分页区域 -->
    <el-pagination
      background
      @current-change="currentChange"
      @size-change="sizeChange"
      :current-page="page"
      :page-size="size"
      layout="sizes, prev, pager, next, jumper, ->, total, slot"
      :total="total"
    >
    </el-pagination>
<!--    <detailDia-->
<!--      v-if="detailVisible"-->
<!--      ref="detailDialog"-->
<!--    ></detailDia>-->
    <watch-dq-info v-if="detailVisible" ref="detailDialog"></watch-dq-info>
  </div>
</template>

<script>
import { pinyin } from "pinyin-pro";
//   import {
//         getFullChars
//     } from '@/utils/convertToPinyin'
import detailDia from "/src/views/modules/dataManage/dataDetail/detailDia.vue";
import watchDqInfo from "/src/views/modules/dataQualityPlugin/watchDqInfo.vue"
// 给设备id，附属设备id，hbase表名
export default {
  name: "dataDetail_chart",
  data() {
    return {
      enterState:0,
      page: 1, //当前第几页
      size: 10, //当前每页页数
      total: 50, //全部数据量
      visible: false,
      // 当前下拉选框对应的hbase表名
      // hbasetablename: "level_hrp7t1_52_1",//subEquip_dianji_8
      // pointnumVisible:false,
      value: "",
      currentEquipId: 0,
      options: [
        {
          id: 1,
          name: "皮带张力",
        },
        {
          id: 2,
          name: "1#滚筒",
        },
        {
          id: 3,
          name: "2#滚筒",
        },
      ],

      tableData: [
        {
          before: [1, 2, 3],
          after: [4, 5, 6],
        },
      ],

      tableHeader: [],

      tableheadertest123:[
        {
          "id": 12,
          "name": "电机电流i",
          "uid": null,
          "createdate": "2021-10-04",
          "delete": null,
          "remark": "电机电流I",
          "type": "float"
        },
        {
          "id": 13,
          "name": "电机电压u",
          "uid": null,
          "createdate": "2021-10-04",
          "delete": null,
          "remark": "电机电压U",
          "type": "float"
        },
        {
          "id": 14,
          "name": "电机a相绕组温度",
          "uid": null,
          "createdate": "2021-10-04",
          "delete": null,
          "remark": "电机A相绕组温度",
          "type": "float"
        },
        {
          "id": 15,
          "name": "电机前轴温",
          "uid": null,
          "createdate": "2021-10-04",
          "delete": null,
          "remark": "电机前轴温",
          "type": "float"
        },
        {
          "id": 39,
          "name": "电机b相绕组温度",
          "uid": null,
          "createdate": "2022-01-08",
          "delete": null,
          "remark": "电机B相绕组温度-1#瓦斯抽放泵",
          "type": "float"
        },
        {
          "id": 40,
          "name": "电机c相绕组温度",
          "uid": null,
          "createdate": "2022-01-08",
          "delete": null,
          "remark": "电机C相绕组温度-1#瓦斯抽放泵",
          "type": "float"
        },
        {
          "id": 41,
          "name": "电机后轴温",
          "uid": null,
          "createdate": "2022-01-08",
          "delete": null,
          "remark": "电机后轴温-1#瓦斯抽放泵",
          "type": "float"
        },
        {
          "id": 205,
          "name": "ceshicedian1",
          "uid": null,
          "createdate": "2022-04-20",
          "delete": null,
          "remark": "123",
          "type": "float"
        },
        {
          "id": 230,
          "name": "电流111",
          "uid": null,
          "createdate": "2022-05-05",
          "delete": null,
          "remark": "111",
          "type": "float"
        },
        {
          "id": 240,
          "name": "test509#1",
          "uid": null,
          "createdate": "2022-05-09",
          "delete": null,
          "remark": "111",
          "type": "float"
        }
      ],

      databaseType: "",
      database: [
        {
          value: "选项1",
          label: "hbase",
        },
        {
          value: "选项2",
          label: "influxdb",
        },
        {
          value: "选项3",
          label: "mysql",
        },
      ],

      currentPage1: 1,
      currentPage2: 2,
      currentPage3: 3,
      currentPage4: 4,
      detailVisible: false,
      moreVisible: false,
      currentSubEquipnameId: "",
      subEuipname: "",
      currentEquipname: "",
      dataListLoading: true,
      optionLoading: true,

      timer: null,

      //保存后端转拼音结果
      pinyinResult: []
    };
  },
  props:['tableHeaderTest','hbaseTableName'],
  components: {
    detailDia,
    watchDqInfo
  },

  methods: {
    // 点击表头查看数据项信息
    mpQuality(column, event) {
        if (column.label === "更多") {
            this.size += 10;
            this.initData();
        }
      // console.log(column);
      // console.log(event);
      if (column.level == 1 && column.property == "properties") {
        var columnData = this.tableHeader.filter((item) => {
          return item.name == column.label;
        });
      }
      console.log(columnData);
      this.detailVisible = true;
      console.log(this.tableHeader, column, event)
      // this.$nextTick(() => {
      //   this.$refs.detailDialog.init(columnData);
      // }
      this.$nextTick(() => {
        this.$refs.detailDialog.init(columnData[0].id);
      });
    },

    confirmEnding(str, target) {
      var start = str.length - target.length;
      var arr = str.substr(start, target.length);
      if (arr == target) {
        return true;
      }
      return false;
    },
    getMore(row) {
      // console.log(row);
    },
    // 改变当前附属设备选项
    // selectCurrentpoint() {
    //   if (this.timer !== null) {
    //     clearInterval(this.timer);
    //     this.timer = null;
    //   }
    //   this.tableData = [];
    //   this.tableHeader = [];
    //   for (let i in this.options) {
    //     if (this.options[i].id == this.currentSubEquipnameId) {
    //       this.subEuipname = this.options[i].name;
    //       this.hbasetablename = this.options[i].hbasetablename;
    //
    //       //              console.log('this.hbasetablename');
    //       // console.log(this.options[0]);
    //       break;
    //     }
    //   }
    //   this.initData();
    // },
    currentChange(currentPage) {
      this.page = currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },
    watch(id) {
      this.detailVisible = true;
      this.$nextTick(() => {
        this.$refs.detailDialog.init(id);
      });
    },
    // more(column, event){
    //   if (column.label === "更多") {
    //       this.size += 10;
    //       this.initData();
    //   }
    // },
    // pointnum(row) {
    //     this.pointnumVisible = true;
    //     this.$nextTick(() => {
    //         this.$refs.pointnumDialog.init(row);
    //     });
    // },

    // 根据传来的设备currentEquipId找到对应的附属设备选项
    getSubEquipOption() {

      // this.getRequest(
      //   "/coalManage/getSubEquipmentInfoByEquipmentId/?equipmentId=" +
      //     this.currentEquipId
      // ).then((resp) => {
      //   if (resp.data) {
      //     console.log("coalManage:"+resp.data);
      //     this.options = resp.data;
      //     this.currentSubEquipname = this.options[0].name; //将当前子系统与id设置为下拉框第一个
      //     this.currentSubEquipnameId = this.options[0].id;//8
      //     this.optionLoading = false;
      //     this.subEuipname = this.options[0].name;//电机
      //     this.hbasetablename = this.options[0].hbasetablename;//subEquip_dianji_8
      //     // console.log(this.hbasetablename);
      //     this.initData();
      //   }
      // });
      this.initData();
    },
    // 根据附属设备id拿到对应的数据项，并展示在table中
    initData() {
      this.dataListLoading = true;
      this.dataListLoading = false;

      // this.currentSubEquipnameId = parseInt(this.currentSubEquipnameId);
      //
      //
      // console.log("initData-----")
      // this.getRequest(
      //   "/measurePointManage/getMeasurePointInfoBySubEquipmentIdWithPinyin/?subEquipmentId=" +
      //     this.currentSubEquipnameId +
      //     "&page=" +
      //     this.page +
      //     "&size=" +
      //     this.size
      // ).then((resp) => {
      //   // this.getRequest('/dataList/getMeasurepointInfoBySubEuipId/?SubEuipId=' + this.currentSubEquipnameId + '&page=' + this.page + '&size=' + this.size).then(resp=>{
      //   if (resp) {
      //     this.tableHeader = resp.data.data;
      //     this.pinyinResult = resp.data.pinyin;
      //     console.log("this.pinyin");
      //     console.log(this.pinyinResult);
      //     this.dataListLoading = false;
      //     console.log("shoudao")
      //     console.log(resp.data.data)
      //     console.log("this.tableHeader");
      //     console.log(this.tableHeader);
      //     this.total = resp.data.total;
      //
      //     //是否显示更多
      //     if(this.total > this.page * this.size){
      //         this.moreVisible = true;
      //     }
      //     else{
      //         this.moreVisible = false;
      //     }
      //   }
      // });
      this.startGetData();
    },
    getHbaseData() {
      this.getRequest(
        "/hbase/getMpInfoByHbaseTablename/?hbasetablename=" +
          this.hbaseTableName
      ).then(({ data }) => {
        console.log("this.hbaseTableName:"+this.hbaseTableName);
        if (data) {
            // let pinyinHeader = this.tableHeader.map((item) => {
            //   return pinyin(item.name, {
            //     toneType: "none",
            //   }).replace(/\s*/g, "");
            // });
           //数据大致格式，不完全相同
            let te = [
              {
                "date": "2023-02-01 08:48:44.239",
                "dianjiaxiangraozuwendu_after": "test",
                "dianjiaxiangraozuwendu_before": "test"
              },
              {
                "date": "2023-02-01 08:48:54.510",
                "dianjiaxiangraozuwendu_after": "test",
                "dianjiaxiangraozuwendu_before": "test"
              },
              {
                "date": "2023-02-01 08:49:01.525",
                "dianjiaxiangraozuwendu_after": "test",
                "dianjiaxiangraozuwendu_before": "test"
              }
            ];
            let pinyinHeader = []
            // let pinyinHeader = this.tableHeaderTest.concat();
            for(let i=0;i<this.tableHeaderTest.length;i++){
              pinyinHeader[i] = this.tableHeaderTest[i].name;
              // console.log('pppp',pinyinHeader[0])
            }
            var arr = [];
            data.forEach((item) => {
              console.log("item",item);
              //item为对象数组中的每一个对象
              let o = {
                //有多个个测点，就有多长的数组
                before: new Array(pinyinHeader.length).fill(" "),
                after: new Array(pinyinHeader.length).fill(" "),
                Isproblem: new Array(pinyinHeader.length).fill(" "),
              };
              console.log("o"+o);
              console.log("pinyinHeader.length:"+pinyinHeader.length)
              o["rowKey"] = item["date"];
              //对item中的每一个属性值做判断
              for (let key in item) {
                //key：数组中每个对象的key值
                //循环表头中的数据项名称
                console.log('key',key)
                console.log('pinheader111',pinyinHeader)

                for (let i = 0; i < pinyinHeader.length; i++) {
                  //如果是治理前的值，通过splice插入对应位置（保证顺序）
                  if (key.startsWith(pinyinHeader[i]) && key.endsWith("before")) {
                    // o.before.splice(i, 1, eval("item." + key));
                    console.log('before1',o.before)
                    o.before.splice(i, 1, item[key]);
                    console.log('before2',o.before)
                  }
                  //治理后同理
                  if (key.startsWith(pinyinHeader[i]) && key.endsWith("after")) {
                    // o.after.splice(i, 1, eval("item." + key));
                    o.after.splice(i, 1, item[key]);
                  }
                }
                //根据治理前后的值判断是否存在问题
                for (let j = 0; j < pinyinHeader.length; j++) {
                  if (o.before[j] != "") {
                    o.Isproblem.splice(
                            j,
                            1,
                            o.before[j] == o.after[j] ? "否" : "是"
                    );
                  }
                }
              }
              //将整理后的对象插入数组中
              arr.push(o);
            });
            console.log("arr");
            console.log(arr);
            this.tableData = arr;
          // })

        }
      });
    },
    startGetData() {
      clearInterval(this.timer);
      this.getRequest(
          "/hbase/isHbaseTableExists/?hbaseTableName=" +
          this.hbaseTableName
      ).then((resp) => {
        if (resp.data.msg === "success") {
                this.getRequest(
                       "/hbase/isHbaseTableExists/?hbaseTableName=" +
                        this.hbaseTableName
                   ).then((resp) => {
                    if (resp.data.msg === "success") {
                       this.timer = setInterval(() => {
                            this.getHbaseData();
                          }, 3000);
                      }

                 })
              }
      })
    }
  },
  mounted() {
    let temp1 = 't9_before'
    console.log('测试是否')
    console.log(temp1.startsWith('t9'))
    console.log(temp1.endsWith("before"))
    // this.currentEquipId = this.$route.query.equipmentId;
    this.currentEquipId = 7;
    // this.currentEquipId = window.sessionStorage.getItem("dataList_currentEquipId");
    // 获取附属设备选项
    this.getSubEquipOption();
    // this.currentSubEquipname = this.options[0].name; //将当前子系统与id设置为下拉框第一个
    // this.currentSubEquipnameId = this.options[0].id;

    // this.initData()
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    console.log("chart kill");
  },
};
</script>

<style scoped></style>
>
